import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { ContentSingle, ContactFormSingle } from "page_components/career"

const Career = ({ pageContext, location }) => {
  return (
    <Layout
      location={location}
      seo={{ title: pageContext?.data?.title }}
      rmLangSwitcher
    >
      <Breadcrumbs
        title={pageContext?.data?.title}
        parent={{ label: "Kariera", url: "/kariera" }}
      />
      <PageHeader title={pageContext?.data?.title} smaller />
      <ContentSingle data={pageContext.data} />
      <ContactFormSingle position={pageContext?.data?.title} />
    </Layout>
  )
}

export default Career
